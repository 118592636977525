import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingOverlayService {
  isShown = false;

  show(): void {
    this.isShown = true;
  }

  hide(): void {
    this.isShown = false;
  }
}
